import React, { useState, useEffect } from 'react';
import creditcard from '../../assests/credit-card.png';
import person from '../../assests/person.png';
import Cookies from 'js-cookie';
import {
    Paper,
    Typography,
    Grid,
    Chip,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    Collapse,
    TablePagination,
    TableHead,
    TableRow,
    Button,
    Box,
    IconButton,
    CircularProgress,
} from '@mui/material';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { styled } from "@mui/system";
import OutlinedFlagIcon from '@mui/icons-material/OutlinedFlag';
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import BusinessIcon from '@mui/icons-material/Business';
import PublicIcon from '@mui/icons-material/Public';
import TablePaginationActions from "./TablePaginationActions";
import ExpandedPersonView from './ExpandedPersonView';
import user_icon from '../../assests/user_icon.png';
import mail_icon from '../../assests/mail_icon.png';
import website_icon from '../../assests/website_icon.png';
import company_icon from '../../assests/company_icon.png';
import { path } from '../../apiCentral/path';

const ExpandedClientView = ({ onBack, selectedClient }) => {
    const defaultPageSize = 10;
    const [rowsPerPage, setRowsPerPage] = useState(defaultPageSize);
    const [currentPage, setCurrentPage] = useState(0);
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const token = Cookies.get('token');
    const [total, setTotal] = useState(0);
    const [totalEnriched, setTotalEnriched] = useState(0);
    const orgId = selectedClient._id;
    const [expandedRow, setExpandedRow] = useState(null);

    useEffect(() => {
        const fetchClients = async () => {
            setLoading(true);
            try {
                const payload = {
                    page: currentPage,
                    size: rowsPerPage,
                    org_id: orgId,
                };

                const response = await fetch(path.getClientDetails, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: token,
                    },
                    body: JSON.stringify(payload),
                });

                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }

                const res = await response.json();
                setData(res.result.slice(currentPage * rowsPerPage, (currentPage + 1) * rowsPerPage) || []);
                setTotal(res.total_documents || 0);
                setTotalEnriched(res.total_documents_enriched || 0);
            } catch (error) {
                console.error('Error fetching data:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchClients();
    }, [currentPage, rowsPerPage, orgId, token]);

    const getSocialIcon = (platform) => {
        switch (platform) {
            case 'Facebook':
                return <FacebookIcon />;
            case 'Twitter':
                return <TwitterIcon />;
            case 'LinkedIn':
                return <LinkedInIcon />;
            case 'Apollo':
                return <PublicIcon />;
            default:
                return <BusinessIcon />;
        }
    };

    const renderIdentityObject = (identities) =>
        Object.entries(identities).map(([key, value], index) => (
            <Typography key={index} variant="body2">
                {key}: {value}
            </Typography>
        ));

    const getPriorityColor = (priority) => {
        const priorityLower = priority?.toLowerCase();
        if (priorityLower === 'low' || priorityLower === 'low priority') return 'error';
        if (priorityLower === 'medium' || priorityLower === 'medium priority') return 'warning';
        if (priorityLower === 'high' || priorityLower === 'top priority') return 'success';
        return 'default';
    };

    const handleExpandClick = (index) => {
        setExpandedRow(expandedRow === index ? null : index);
    };

    const handleRequestChange = (key, value) => {
        if (key === "page") {
            setCurrentPage(value);
        } else if (key === "size") {
            setRowsPerPage(value);
            setCurrentPage(0); // Reset to first page when changing page size
        }
    };


    return (
        <Paper sx={{ padding: 4, borderRadius: 1, margin: 'auto', position: 'relative' }}>
            {loading ? (
                <Grid container justifyContent="center" alignItems="center" sx={{ height: '100vh' }}>
                    <CircularProgress />
                </Grid>
            ) : (
                <>
                    <Box display="flex" alignItems="center" sx={{ marginBottom: 2 }}>
                        <IconButton
                            aria-label="back"
                            onClick={onBack}
                            sx={{
                                marginRight: 2,
                                '&:hover': { backgroundColor: 'transparent' },
                            }}
                        >
                            <ArrowBackIcon />
                        </IconButton>
                        <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                            {selectedClient ? selectedClient.display_name : 'Client Name'}
                        </Typography>
                    </Box>
                    <Grid container spacing={4} sx={{ marginBottom: 2, marginTop: 2 }} alignItems="center">
                        <Grid item xs={12} sm={3}>
                            <Paper
                                sx={{
                                    padding: 2,
                                    textAlign: 'center',
                                    backgroundColor: '#AF18FF',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    borderRadius: 3,
                                    position: 'relative',
                                }}
                            >
                                <Box display="flex" flexDirection="column" gap={2} width="100%">
                                    <Typography variant="h6" sx={{ color: '#fff', marginBottom: 1 }}>
                                        Total Cards
                                    </Typography>
                                    <Typography variant="h4" sx={{ fontWeight: 'semibold', color: '#fff', paddingBottom: 3 }}>
                                        {total}
                                    </Typography>
                                </Box>
                                <img
                                    src={creditcard}
                                    alt="Credit Card"
                                    style={{ position: 'absolute', bottom: 0, right: 0, width: '141px', height: '78px' }}
                                />
                            </Paper>
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <Paper
                                sx={{
                                    padding: 2,
                                    textAlign: 'center',
                                    backgroundColor: '#077FFF',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    borderRadius: 3,
                                    position: 'relative',
                                }}
                            >
                                <Box display="flex" flexDirection="column" gap={2} width="100%">
                                    <Typography variant="h6" sx={{ color: '#fff', marginBottom: 1 }}>
                                        Total Cards Enriched
                                    </Typography>
                                    <Typography variant="h4" sx={{ fontWeight: 'semibold', color: '#fff', paddingBottom: 3 }}>
                                        {totalEnriched}
                                    </Typography>
                                </Box>
                                <img
                                    src={person}
                                    alt="Person"
                                    style={{ position: 'absolute', bottom: 0, right: 0, width: '118px', height: '109px' }}
                                />
                            </Paper>
                        </Grid>
                    </Grid>
                    {/* Table */}
                    <TableContainer component={Paper}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell><strong>Person Name</strong></TableCell>
                                    <TableCell><strong>Company Name</strong></TableCell>
                                    <TableCell><strong>Priority</strong></TableCell>
                                    <TableCell><strong>Social Profiles</strong></TableCell>
                                    <TableCell><strong>Company Profiles</strong></TableCell>
                                    <TableCell><strong>Flag</strong></TableCell>
                                    <TableCell></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {data.map((row, index) => (
                                    <React.Fragment key={index}>
                                        <TableRow
                                            onClick={() => handleExpandClick(index)}
                                            sx={{
                                                backgroundColor: index % 2 === 1 ? 'white' : '#ECECEC',
                                                cursor: 'pointer',
                                            }}
                                        >
                                            <TableCell>{row.person_name}</TableCell>
                                            <TableCell>{row.company_name}</TableCell>
                                            <TableCell>
                                                <Chip
                                                    label={row.priority}
                                                    color={getPriorityColor(row.priority)}
                                                />
                                            </TableCell>
                                            <TableCell>
                                                {renderIdentityObject(row.personal_identities || {})}
                                            </TableCell>
                                            <TableCell>
                                                {renderIdentityObject(row.company_identities || {})}
                                            </TableCell>
                                            <TableCell>
                                                <OutlinedFlagIcon
                                                    sx={{
                                                        color: row.flag ? 'blue' : 'grey',
                                                    }}
                                                />
                                            </TableCell>
                                            <TableCell>
                                                <Box display="flex" justifyContent="space-between" alignItems="center" gap={4}>
                                                    <a href={`mailto:${row.email}`} target="_blank" rel="noopener noreferrer">
                                                        <img
                                                            src={mail_icon}
                                                            alt="Mail"
                                                            style={{
                                                                width: 24,
                                                                height: 24,
                                                                cursor: 'pointer',
                                                            }}
                                                        />
                                                    </a>
                                                    <a href={row.website} target="_blank" rel="noopener noreferrer">
                                                        <img
                                                            src={website_icon}
                                                            alt="Website"
                                                            style={{
                                                                width: 24,
                                                                height: 24,
                                                                cursor: 'pointer',
                                                            }}
                                                        />
                                                    </a>
                                                    <a href={row.user_profile} target="_blank" rel="noopener noreferrer">
                                                        <img
                                                            src={user_icon}
                                                            alt="User "
                                                            style={{
                                                                width: 24,
                                                                height: 24,
                                                                cursor: 'pointer',
                                                            }}
                                                        />
                                                    </a>
                                                    <a href={row.company_profile} target="_blank" rel="noopener noreferrer">
                                                        <img
                                                            src={company_icon}
                                                            alt="Company"
                                                            style={{
                                                                width: 24,
                                                                height: 24,
                                                                cursor: 'pointer',
                                                            }}
                                                        />
                                                    </a>
                                                </Box>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell style={{backgroundColor: '#ECECEC',width:'100%',paddingTop:0,paddingBottom:0}} colSpan={7}>
                                                <Collapse in={expandedRow === index} timeout="auto" unmountOnExit>
                                                    <ExpandedPersonView data={row} />
                                                </Collapse>
                                            </TableCell>
                                        </TableRow>
                                    </React.Fragment>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={[defaultPageSize, 25, 50, 100]}
                        component="div"
                        count={total}
                        rowsPerPage={rowsPerPage}
                        page={currentPage}
                        onPageChange={(event, newPage) => {
                            setCurrentPage(newPage);
                        }}
                        onRowsPerPageChange={(event) => {
                            const newRowsPerPage = parseInt(event.target.value, 10);
                            setRowsPerPage(newRowsPerPage);
                            setCurrentPage(0);
                        }}
                    />
                </>
            )}
        </Paper>
    );
};

export default ExpandedClientView;
