import React, { useRef, useState, useEffect } from "react";
import { auth } from "../config/firebase/firebase";
import { signInWithEmailAndPassword } from "firebase/auth";
import axios from "axios";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import { path } from "../apiCentral/path";
import { CircularProgress } from "@mui/material";
import "./Login.css";
const _ = require("lodash");

function LoginContainer() {
  const email = useRef();
  const password = useRef();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    // Firebase Authentication
    let signInRes;
    try {
      signInRes = await signInWithEmailAndPassword(
        auth,
        email?.current?.value,
        password?.current?.value
      );
      
    } catch (error) {
      handleAuthError(error);
      return;
    }
    try {
      // console.log("SIGN IN RES: " + JSON.stringify(signInRes));
      let expiresIn = Number(signInRes._tokenResponse.expiresIn) - 10; // Subtracting 10 seconds to avoid edge cases
      const expirationDate = new Date(new Date().getTime() + expiresIn * 1000);
      Cookies.set("token", signInRes._tokenResponse.idToken, {
        expires: expirationDate,
      });
    } catch (error) {
      // console.error("Error persisting token in Cookies: ", error);
    }

    //Backend Authentication
    let response;
    try {
      response = await axios.post(
        path.loginUrl, {},
        {
          headers: {
            authorization: `${Cookies.get("token")}`,
          },
        }
      );

      // console.log(response);

      if (response?.status === 200) {
        if (response?.data?.status) {
          const userType = response?.data?.user_type;
          if (userType) {
            Cookies.set("user_type", userType, { expires: 7 }); 
          }
          navigate("/dashboard?tab=analytics");
        } else {
          alert(response?.data?.message);
          Cookies.remove("token");
        }
      } else {
        Cookies.remove("token");
      }
    } catch (error) {
      alert(error?.response?.data?.message || "An error occurred. Please try again.");
      Cookies.remove("token");
      setIsLoading(false); // Ensure loading is reset in case of error
      return;
    }

    setIsLoading(false);

  };

  const handleAuthError = (error) => {
    let errorMessage;

    switch (error.code) {
      case "auth/invalid-email":
        errorMessage = "Invalid email address. Please check and try again.";
        break;
      case "auth/invalid-credential":
        errorMessage = "Invalid Credentials. Please check and try again.";
        break;
      case "auth/user-not-found":
        errorMessage = "No user found with this email.";
        break;
      case "auth/wrong-password":
        errorMessage = "Incorrect password. Please try again.";
        break;
      case "auth/network-request-failed":
        errorMessage =
          "Network error. Please check your internet connection and try again.";
        break;
      default:
        errorMessage = "An unknown error occurred. Please try again later.";
        break;
    }

    // console.error("Error signing in:", errorMessage);
    alert(errorMessage);
    setIsLoading(false);
  };

  return (
    <div className="login-container">
      <div className="login-popup">
        <h2>Login</h2>
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label>Email:</label>
            <input type="email" ref={email} required />
          </div>
          <div className="form-group">
            <label>Password:</label>
            <input type="password" ref={password} required />
          </div>
          <button className="login-button" type="submit" disabled={isLoading}>{isLoading ?
            <div style={{ height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <CircularProgress style={{ color: 'white' }} size={20} thickness={7} />
            </div> : `Login`}</button>
        </form>
      </div>
    </div>
  );
}

export default LoginContainer;
