import React, { useState } from "react";
import {
    Grid,
    TextField,
    InputAdornment,
    Button,
    ThemeProvider,
    createTheme,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import FilterListIcon from "@mui/icons-material/FilterList";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import AddNewCardsPopup from "./popups/AddNewCardsPopup";
import StoredFilesPopup from "./popups/StoredFilesPopup";
import SelectUploadTypePopup from "./popups/SelectClientPopup";
import { openDB } from 'idb';

const theme = createTheme({
    components: {
        MuiTextField: {
            styleOverrides: {
                root: {
                    "& .MuiInputBase-root": {
                        height: 40,
                        marginBottom: 20,
                    },
                },
            },
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    height: 40,
                    marginBottom: 20,
                },
            },
        },
    },
});

function Client() {
    const [addNewCardsPopupOpen, setAddNewCardsPopupOpen] = useState(false);
    const [storedFilesPopupOpen, setStoredFilesPopupOpen] = useState(false);
    const [selectUploadTypePopupOpen, setSelectUploadTypePopupOpen] = useState(false);
    const [uploadType, setUploadType] = useState('');
    const [isFilterPopupOpen, setIsFilterPopupOpen] = useState(false);
    const handlePopupOpen = async () => {
        const openDatabase = async () => {
          return await openDB('fileStore', 1, {
            upgrade(db) {
              if (!db.objectStoreNames.contains('files')) {
                db.createObjectStore('files', { keyPath: 'id', autoIncrement: true });
              }
            },
          });
        };
      
        const db = await openDatabase();
        const tx = db.transaction('files', 'readonly');
        const store = tx.objectStore('files');
        const storedFiles = await store.getAll(); 
      
        if (storedFiles.length > 0) {
          setStoredFilesPopupOpen(true);
        } else {
          setSelectUploadTypePopupOpen(true);
        }
      };
    
      const handleAddNewCardsPopupClose = () => setAddNewCardsPopupOpen(false);
      const handleStoredFilesPopupClose = () => setStoredFilesPopupOpen(false);
      const handleSelectUploadTypePopupClose = () => setSelectUploadTypePopupOpen(false);
    
      const handleUploadTypeSelection = (selectedOption) => {
        setUploadType(selectedOption);
        setAddNewCardsPopupOpen(true);
      };

    const handleFilterButtonClick = () => {
        setIsFilterPopupOpen(true);
    };

    const handleFilterPopupClose = () => {
        setIsFilterPopupOpen(false);
    };

    return (
        <ThemeProvider theme={theme}>
            <form onSubmit={(e) => e.preventDefault()}>
                <Grid container alignItems="center" spacing={2}>
                    <Grid item xs={12} sm={6} md={4}>
                        <TextField
                            fullWidth
                            variant="outlined"
                            placeholder="Search for name, company name, designation"
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <SearchIcon />
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </Grid>
                    <Grid item>
                        <Button
                            variant="contained"
                            startIcon={<FilterListIcon />}
                            sx={{ textTransform: "none", fontSize: "1rem" }}
                            onClick={handleFilterButtonClick}
                        >
                            Filter
                        </Button>
                    </Grid>
                    <Grid item>
                        <Button
                            variant="contained"
                            startIcon={<FileUploadIcon />}
                            sx={{ textTransform: "none", fontSize: "1rem" }}
                            onClick={handlePopupOpen}
                        >
                            Upload
                        </Button>
                    </Grid>
                    <StoredFilesPopup
                    open={storedFilesPopupOpen}
                    handleClose={handleStoredFilesPopupClose}
                    handleNext={() => {
                      handleStoredFilesPopupClose();
                      setAddNewCardsPopupOpen(true);
                    }}
                  />
                  <AddNewCardsPopup
                    open={addNewCardsPopupOpen}
                    handleClose={handleAddNewCardsPopupClose}
                    uploadType={uploadType}
                  />
                  <SelectUploadTypePopup
                    open={selectUploadTypePopupOpen}
                    handleClose={handleSelectUploadTypePopupClose}
                    handleSelection={handleUploadTypeSelection}
                  />
                </Grid>
            </form>
        </ThemeProvider>
    );
}

export default Client;