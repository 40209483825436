import { initializeApp } from "firebase/app";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import Cookies from 'js-cookie';

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECTID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDERID,
  appId: process.env.REACT_APP_FIREBASE_APPID,
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

const refreshToken = () => {
  onAuthStateChanged(auth, async (user) => {
    if (!Cookies.get('token')) return;

    if (user) {
      try {
        const idToken = await user.getIdToken(true);
        // console.log("REFRESHING TOKEN: " + idToken);
        const expiresIn = 3590; // Token validity in seconds
        const expirationDate = new Date(new Date().getTime() + expiresIn * 1000);
        Cookies.set('token', idToken, { expires: expirationDate });
      } catch (error) {
        console.error('Error refreshing token:', error);
        Cookies.remove('token');
      }
    } else {
      Cookies.remove('token');
    }
  });
};


export { auth, app, refreshToken };
