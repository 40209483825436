import React, { useState, useEffect } from "react";
import { PhoneIphone, DesktopWindows, Dashboard, Apple } from "@mui/icons-material";
import {
    Grid,
    TextField,
    InputAdornment,
    Button,
    ThemeProvider,
    createTheme,
    Paper,
    Table,
    TableBody,
    Tooltip,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TablePagination,
    Chip,
    Collapse,
    CircularProgress,
} from "@mui/material";
import { styled } from "@mui/system";
import { path } from "../../apiCentral/path";
import SearchIcon from "@mui/icons-material/Search";
import FilterListIcon from "@mui/icons-material/FilterList";
import TablePaginationActions from "./TablePaginationActions";
import FilterPopup from "./popups/FilterPopup";
import Cookies from "js-cookie";
import MfiDetails from "./MfiDetails";

const defaultPageSize = 10;

const theme = createTheme({
    components: {
        MuiTextField: {
            styleOverrides: {
                root: {
                    "& .MuiInputBase-root": {
                        height: 40,
                        marginBottom: 20,
                    },
                },
            },
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    height: 40,
                    marginBottom: 20,
                },
            },
        },
    },
});

function MFI() {
    const [rowsPerPage, setRowsPerPage] = useState(defaultPageSize);
    const [currentPage, setCurrentPage] = useState(0);
    const [total, setTotal] = useState(0);
    const [data, setData] = useState([]);
    const [expandedRow, setExpandedRow] = useState(null);
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const [filter, setFilter] = useState("");
    const [reload, setReload] = useState({});
    const [isFilterPopupOpen, setIsFilterPopupOpen] = useState(false);
    const [searchString, setSearchString] = useState("");
    const [isFilterApplied, setIsFilterApplied] = useState(false);
    const [loading, setLoading] = useState(true);
    const [processFilter, setProcessFilter] = useState([]);
    const [appliedFilters, setAppliedFilters] = useState({
        startDate: "",
        endDate: "",
        filter: "",
        processFilter: [],
    });
    
    const token = Cookies.get('token');
    // console.log(data);

    // Function to handle row expansion/collapse based on the index
    const handleExpandClick = (index) => {
        setExpandedRow(expandedRow === index ? null : index);
    };

    const getDeviceIcon = (device, deviceModel) => {
        let icon;
        switch (device?.toLowerCase()) {
          case "dashboard":
            icon = <Dashboard style={{ color: "#5E5EDF" }} />;
            break;
          case "android":
          case "mobile":
            icon = <PhoneIphone style={{ color: "#9A8E9A" }} />;
            break;
          case "desktop":
            icon = <DesktopWindows style={{ color: "#FFD700" }} />;
            break;
          case "ios":
            icon = <Apple style={{ color: "#A2AAAD" }} />;
            break;
          default:
            icon = <Dashboard style={{ color: "#E0E0E0" }} />;
        }
      
        // Ensuring the tooltip always displays
        return (
          <Tooltip title={deviceModel || "No Device"} arrow>
            {icon}
          </Tooltip>
        );
      };
      
    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                let payload = {
                    page: currentPage,
                    size: rowsPerPage,
                    search: searchString,
                };
                if (startDate !== "") {
                    payload.start_date = startDate;
                    payload.end_date = endDate !== "" ? endDate : new Date().toISOString().split('T')[0];
                }
                if (filter !== "") {
                    payload.filter = Array.isArray(filter) ? filter : [filter];
                }
                if (processFilter !== "") {
                    payload.processFilter = Array.isArray(processFilter) ? processFilter : [processFilter];
                }
                const response = await fetch(path.qaAnalytics, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": token,
                    },
                    body: JSON.stringify(payload),
                });

                const res = await response.json();
                setData(res.result || []);
                setTotal(res.total || 0);
            } catch (error) {
                // console.error("Error fetching data:", error);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [currentPage, rowsPerPage, reload, searchString]);

    // Function to handle changes in page number or page size
    const handleRequestChange = (key, value) => {
        if (key === "page") {
            setCurrentPage(value);
        } else if (key === "size") {
            setRowsPerPage(value);
            setCurrentPage(0);
        }
    };

    // Function to handle changes in the number of rows per page
    const handleRowsPerPageChange = (event) => handleRequestChange("size", parseInt(event.target.value, 10));

    const handleFilterButtonClick = () => {
        setIsFilterPopupOpen(true);
    };

    const handleFilterPopupClose = () => {
        setIsFilterPopupOpen(false);
    };

    // Function to reset filters and reload the data
    const handleReload = () => {
        setReload({ startDate, endDate, filter });
        setCurrentPage(0);
        setIsFilterPopupOpen(false);
        setIsFilterApplied(true);
        setAppliedFilters({ startDate, endDate, filter, processFilter });
    };


    //Reset filters
    const handleResetFilters = () => {
        setStartDate("");
        setEndDate("");
        setFilter("");
        setIsFilterPopupOpen(false);
        setIsFilterApplied(false);
        setReload({});
        setProcessFilter([]);
        setCurrentPage(0);
        setAppliedFilters({ startDate: "", endDate: "", filter: "", processFilter: [] });
    };

    const handleSearchChange = (event) => setSearchString(event.target.value);
    

    return (
        <ThemeProvider theme={theme}>
            <form onSubmit={(e) => e.preventDefault()}>
                <Grid container alignItems="center" spacing={2}>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            fullWidth
                            variant="outlined"
                            placeholder="Search for name, company name, designation"
                            value={searchString}
                            onChange={handleSearchChange}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <SearchIcon />
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </Grid>
                    <Grid item container xs={12} sm={6} spacing={2}>
                        <Grid item>
                            <Button
                                variant="contained"
                                startIcon={<FilterListIcon />}
                                sx={{ textTransform: "none", fontSize: "1rem" }}
                                onClick={handleFilterButtonClick}
                            >
                                Filter {isFilterApplied && "*"}
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </form>
            {loading ? (
                <Grid container justifyContent="center" alignItems="center" style={{ height: '100vh' }}>
                    <CircularProgress />
                </Grid>
            ) : (
                <Paper sx={{ width: "100%", overflow: "hidden" }}>
                    <TableContainer>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell sx={{ fontWeight: 'bold', width: '10%' }}>Client</TableCell>
                                    <TableCell sx={{ fontWeight: 'bold', width: '10%' }}>Device</TableCell>
                                    <TableCell sx={{ fontWeight: 'bold', width: '10%' }}>Card Owner</TableCell>
                                    <TableCell sx={{ fontWeight: 'bold', width: '10%' }}>Reason</TableCell>
                                    <TableCell sx={{ fontWeight: 'bold', width: '10%' }}>Message</TableCell>
                                    <TableCell sx={{ fontWeight: 'bold', width: '10%' }}>Status</TableCell>
                                    <TableCell sx={{ fontWeight: 'bold', width: '10%' }}>Date & Time Marked</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {Array.isArray(data) && data.length > 0 ? (
                                    data.map((item, index) => (
                                        <React.Fragment key={index}>
                                            <TableRow
                                                style={{
                                                    backgroundColor: index % 2 === 1 ? "white" : "#ECECEC",
                                                    cursor: "pointer",
                                                }}
                                                onClick={() => handleExpandClick(index)}
                                            >
                                                <TableCell>{item.client}</TableCell>
                                                <TableCell>{getDeviceIcon(item.device, item.device_model)}</TableCell>
                                                <TableCell>{item.cardOwner}</TableCell>
                                                <TableCell>{item.reason}</TableCell>
                                                <TableCell>{item.feedback_message !== "" ? "Present" : "Not Present"}</TableCell>
                                                <TableCell>
                                                    <Chip
                                                        label={item.resolved ? "Resolved" : "Pending"}
                                                        sx={{
                                                            color: "white",
                                                            backgroundColor: item.resolved ? "#46B973" : "#D32F2F",
                                                            cursor: "default",
                                                            "&:hover": {
                                                                backgroundColor: item.resolved ? "#46B973" : "#D32F2F",
                                                            },
                                                        }}
                                                    />
                                                </TableCell>
                                                <TableCell>
                                                    {new Date(item.created_at).toLocaleDateString(undefined, {
                                                        year: "numeric",
                                                        month: "short",
                                                        day: "numeric",
                                                        hour: "2-digit",
                                                        minute: "2-digit",
                                                    })}
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell
                                                    style={{
                                                        paddingBottom: 0,
                                                        paddingTop: 0,
                                                        backgroundColor: "#ECECEC",
                                                    }}
                                                    colSpan={7}
                                                >
                                                    <Collapse in={expandedRow === index} timeout="auto" unmountOnExit>
                                                        <MfiDetails data={data[index]} /> {/* Pass the relevant data */}
                                                    </Collapse>
                                                </TableCell>
                                            </TableRow>
                                        </React.Fragment>
                                    ))
                                ) : (
                                    <TableRow>
                                        <TableCell colSpan={7} align="center">
                                            No data available
                                        </TableCell>
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={Array.from({ length: 50 }, (_, index) => index + 1)}
                        component="div"
                        count={total}
                        rowsPerPage={rowsPerPage}
                        page={currentPage}
                        onPageChange={(event, newPage) => handleRequestChange("page", newPage)}
                        onRowsPerPageChange={handleRowsPerPageChange}
                        ActionsComponent={TablePaginationActions}
                        slotProps={{
                            select: {
                                MenuProps: {
                                    PaperProps: {
                                        style: {
                                            maxHeight: "200px",
                                        },
                                        component: styled('div')(({ theme }) => ({
                                            '&::-webkit-scrollbar': {
                                                display: 'none',
                                            },
                                            scrollbarWidth: 'none',
                                        })),
                                    },
                                },
                            },
                        }}
                    />
                </Paper>
            )}
            {isFilterPopupOpen && (
                <FilterPopup
                    open={isFilterPopupOpen}
                    onClose={handleFilterPopupClose}
                    processFilter={processFilter}
                    setProcessFilter={setProcessFilter}
                    setStartDate={setStartDate}
                    setEndDate={setEndDate}
                    setFilter={setFilter}
                    handleFinalSubmit={handleReload}
                    handleResetFilters={handleResetFilters}
                    appliedFilters={appliedFilters}
                    filter={filter}
                    startDate={startDate}
                    endDate={endDate}
                />
            )}
        </ThemeProvider>
    );
}

export default MFI;
