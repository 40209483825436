import React from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';

const ResolvedPopup = ({ open, handleClose, handleContinue }) => {
  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Resolved</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Are you sure you want to resolve this card?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="default">
          Cancel
        </Button>
        <Button 
          onClick={handleContinue} 
          variant="contained"
          sx={{ backgroundColor: '#28A745', '&:hover': { backgroundColor: '#218838' } }}
        >
          Continue
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ResolvedPopup;