import React from "react";
import CloseIcon from "@mui/icons-material/Close";
import { Paper, Grid, Typography, Dialog, Button, IconButton } from "@mui/material";

function WebsitePopup({ isPopupOpen, handlePopupClose, handleConfirm }) {
  return (
    <Dialog
      open={isPopupOpen}
      onClose={handlePopupClose}
      maxWidth="xs"
      fullWidth={false}
    >
      <Paper
        sx={{
          overflow: "hidden",
          border: "1px solid #d0d0d0",
          padding: "15px",
          width: "400px",
          maxHeight: "400px",
          margin: "auto",
          display: 'flex',
          flexDirection: 'column',
          position: 'relative',
        }}
      >
        <IconButton
          aria-label="close"
          onClick={handlePopupClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: 'grey',
          }}
        >
          <CloseIcon />
        </IconButton>
        <Typography variant="h6" align="center" gutterBottom
          sx={{
            padding: "15px 0",
          }}>
          You are about to be redirected to another website. Do you want to continue?
        </Typography>
        <form style={{ flexGrow: 1, display: 'flex', flexDirection: 'column' }}>
          <Grid item container justifyContent="center" sx={{ marginTop: "10px", columnGap: "15px" }}>
            <Button onClick={handlePopupClose} style={{ color: "#000000", fontWeight: "bold", width: "150px" }}>
              Cancel
            </Button>
            <Button variant="contained" color="primary" type="button" style={{ width: "150px" }} onClick={handleConfirm}>
              Continue
            </Button>
          </Grid>
        </form>
      </Paper>
    </Dialog>
  );
}

export default WebsitePopup;
