import React, { useState, useEffect } from "react";
import axios from "axios";

import RotateRightIcon from '@mui/icons-material/RotateRight';
import {
  TextField,
  Grid,
  Typography,
  CardMedia,
  IconButton,
  Tooltip,
  InputAdornment,
  CardContent,
  Card,
  Divider,
  Button,
  Chip,
  MenuItem,
  FormControl,
  InputLabel,
  Select
} from "@mui/material";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import EditIcon from "@mui/icons-material/Edit";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import Cookies from 'js-cookie';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { path } from "../../apiCentral/path";
import { format } from 'date-fns';
import LaunchIcon from '@mui/icons-material/Launch';
import AddTaskIcon from '@mui/icons-material/AddTask';
import update from '../../assests/update.svg';
import CircularProgress from '@mui/material/CircularProgress';
const _ = require("lodash");

const ExpandedPersonView = ({data}) => {
  // const data = {
  //   "company_identities": {
  //     "linkedin": "https://www.linkedin.com/company/habsytechnologies",
  //     "instagram": "https://www.instagram.com/habsytechnologies"
  //   },
  //   "company_name": "HABSY TECHNOLOGIES PVT LTD",
  //   "created_at": "Mon, 05 Aug 2024 03:31:24 GMT",
  //   "designation": "Founder & CEO",
  //   "emails": [
  //     "aazim@habsy.in",
  //     "info@habsy.in",
  //     "support@habsy.in"
  //   ],
  //   "event_flag": false,
  //   "message": "",
  //   "person_name": "AAZIM BILL SE YASWANT",
  //   "personal_identities": {
  //     "linkedin": "https://www.linkedin.com/in/aazimyaswant",
  //     "instagram": "https://www.instagram.com/aazimyaswant"
  //   },
  //   "priority": "Other",
  //   "qa_updated_at": "",
  //   "qa_user_ref_recent": "",
  //   "updated_at": "Mon, 05 Aug 2024 03:31:45 GMT",
  //   "website": "https://www.habsytechnologies.com"
  // }

  const [formData, setFormData] = useState(data);
  const token = Cookies.get('token');
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [editMode, setEditMode] = useState(false);
  const [popupOpen, setPopupOpen] = useState(false);
  const [editedFields, setEditedFields] = useState({});
  const [lastUpdatedUser, setLastUpdatedUser] = useState(formData?.qa_user_display_name);
  const [lastUpdatedTime, setLastUpdatedTime] = useState(formData?.qa_updated_at);
  const [isresolved, setIsResolved] = useState(formData?.resolved);
  const [websitePopupOpen, setWebsitePopupOpen] = useState(false);
  const [urlToRedirect, setUrlToRedirect] = useState('');
  const editedFieldsByQA = formData?.edited_fields_by_qa || [];
  const [loading, setLoading] = useState({ front: true, back: true });
  console.log(formData)

  const renderUpdateIcon = (fieldName) => (
    editedFieldsByQA.includes(fieldName) ? (
      <InputAdornment position="end">
        <AddTaskIcon style={{ color: 'green', paddingRight: "10px", height: "35px", width: "35px" }} />
      </InputAdornment>
    ) : null
  );

  const areArraysEqual = (arr1, arr2) => {
    if (arr1.length !== arr2.length) return false;
    return arr1.every((item, index) => item === arr2[index]);
  };

  // Compares the value of a field in `data` with its counterpart in `data.front_img_entities` to check for differences.
  const compareFields = (fieldName) => {
    const currentValue = data?.[fieldName] ?? [];
    const referenceValue = data?.front_img_entities?.[fieldName] ?? [];

    if (Array.isArray(currentValue) && Array.isArray(referenceValue)) {
      return !areArraysEqual(currentValue, referenceValue);
    }
    return currentValue !== referenceValue;
  };


  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setEditedFields((prev) => ({
      ...prev,
      [name]: value,
    }));
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const formatURL = (url) => {
    if (!url) return '';
    return url?.startsWith('https://') ? url : `https://${url}`;
  };


  // Saves updated data, including changes and image rotations, to the server and updates state based on the server response.
  const handleSave = async () => {
    // console.log("formData:", formData); 
    // Create a temporary version of updated data
    const updatedData = {
      scan_uid: formData.scan_uid,
      uid: formData.uid,
      ...Object.keys(formData).reduce((changes, key) => {
        if (formData[key] !== data[key]) {
          if (key === 'emails' && typeof formData[key] === 'string') {
            changes[key] = formData[key].split(',').map(email => email.trim());
          } else if (key === 'mobile_numbers' && typeof formData[key] === 'string') {
            changes[key] = formData[key].split(',').map(number => number.trim());
          } else if (key === 'categories' && Array.isArray(formData[key])) {
            changes[key] = formData[key];
          } else {
            changes[key] = formData[key];
          }
        }
        return changes;
      }, {}),
    };

    setFormData(prevData => ({
      ...prevData,
      ...updatedData,
    }));

    setEditMode(false);
    setEditedFields({});
    setIsLoading(true);

    try {
      const response = await axios.put(path.getClientDetails, updatedData, {
        headers: {
          'Authorization': token,
          'Content-Type': 'application/json',
        },
      });

      if (response.status === 200) {

        setFormData(prevData => ({
          ...prevData,
        }));
      } else {
        setError('Failed to save changes.');
      }
    } catch (error) {
      console.error('Error details:', error);
      setError('An error occurred while saving changes.');
    } finally {
      setIsLoading(false);
    }
  };

  const handleCancel = () => {
    setFormData(data);
    setEditMode(false);
    setEditedFields({});
  };

  //Funtion to handle copy 
  const handleCopyClick = (e, contentType, contentToCopy) => {
    e.preventDefault();
    if (navigator.clipboard) {
      navigator.clipboard.writeText(contentToCopy).catch((error) => { });
    } else {
      const textArea = document.createElement("textarea");
      textArea.value = contentToCopy;
      document.body.appendChild(textArea);
      textArea.select();
      document.execCommand('copy');
      document.body.removeChild(textArea);
    }
  };

  const toggleEditMode = () => {
    setEditMode(true);
  };

  return (
    <div style={{ backgroundColor: "#ECECEC" }}>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-end",
          marginBottom: 10,
          backgroundColor: "#ECECEC"
        }}
      >
        <Button
          variant="contained"
          sx={{
            textTransform: "none",
            fontSize: "1rem",
            marginRight: "25px",
            marginTop: "20px",
            backgroundColor: isresolved ? "#DADADA" : "primary",
          }}
          onClick={handleSave}
          disabled={isresolved}
        >
          Save
        </Button>
      </div>
      <Divider style={{ margin: "20px 0" }} />
      <Grid container spacing={2} marginBottom={5} style={{ backgroundColor: "#ECECEC" }}>
        <Grid item xs={12} md={6} style={{ display: 'flex', flexDirection: 'column', gap: '12px' }}>
          {Array.isArray(formData.emails) &&
            formData?.emails.map((email, index) => (
              <TextField
                key={index}
                label={`Email ${index + 1}`}
                variant="outlined"
                fullWidth
                name={`emails[${index}]`}
                onChange={editMode ? handleInputChange : undefined}
                value={email || ''}
                disabled={!editMode}
                style={{ backgroundColor: isresolved ? "#DADADA" : "white", height: "60px" }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end" style={{ paddingRight: '10px' }}>
                      {email && compareFields('email') && (
                        <img src={update} alt="" />
                      )}
                      {renderUpdateIcon('email')}
                      <Tooltip title="Copy">
                        <IconButton onClick={(e) => handleCopyClick(e, "email", email.join(', ') || '')}>
                          <ContentCopyIcon />
                        </IconButton>
                      </Tooltip>
                    </InputAdornment>
                  ),
                  style: { padding: '30px 0px', height: '40px' },
                }}
                InputLabelProps={{
                  style: { color: email ? '#6E6E6E' : '#B0B0B0' },
                }}
              />
            ))}
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            label="Website/url"
            variant="outlined"
            fullWidth
            name="website"
            onChange={editMode ? handleInputChange : undefined}
            value={formData?.website || ''}
            disabled={!editMode}
            style={{ backgroundColor: isresolved ? "#DADADA" : "white", height: "60px" }}
            InputProps={{
              endAdornment: !editMode && formData?.website && (
                <InputAdornment position="end" style={{ paddingRight: '10px' }}>
                  {formData?.website && compareFields('website') && (
                    <img src={update} alt="" />
                  )}
                  {renderUpdateIcon('website')}
                  <Tooltip title="Visit Website">
                    <IconButton>
                      <LaunchIcon />
                    </IconButton>
                  </Tooltip>
                </InputAdornment>
              ),
              style: { padding: '30px 0px', height: '40px' },
            }}
            InputLabelProps={{
              style: { color: formData?.website ? '#6E6E6E' : '#B0B0B0' },
            }}
          />
        </Grid>
        <Grid item xs={12} md={6} style={{ display: 'flex', flexDirection: 'column', gap: '12px' }}>
          <Typography variant="subtitle1" fontWeight="semibold">Person Social Identity</Typography>
          {formData?.personal_identities &&
            Object.entries(formData.personal_identities).map(([platform, url], index) => (
              <TextField
                key={index}
                label={platform} // Social media platform name
                variant="outlined"
                fullWidth
                name={`personal_identities.${platform}`}
                onChange={(e) =>
                  setFormData((prevData) => ({
                    ...prevData,
                    personal_identities: {
                      ...prevData.personal_identities,
                      [platform]: e.target.value, // Update the URL for the platform
                    },
                  }))
                }
                value={url || ''}
                disabled={!editMode}
                style={{ backgroundColor: isresolved ? '#DADADA' : 'white', height: '60px' }}
                InputProps={{
                  style: { padding: '30px 0px', height: '40px' },
                }}
                InputLabelProps={{
                  style: { color: url ? '#6E6E6E' : '#B0B0B0' },
                }}
              />
            ))}
        </Grid>

        <Grid item xs={12} md={6} style={{ display: 'flex', flexDirection: 'column', gap: '12px' }}>
          <Typography variant="subtitle1" fontWeight="semibold">Company Social Identity</Typography>
          {formData?.company_identities &&
            Object.entries(formData.company_identities).map(([platform, url], index) => (
              <TextField
                key={index}
                label={platform} // Social media platform name
                variant="outlined"
                fullWidth
                name={`company_identities.${platform}`}
                onChange={(e) =>
                  setFormData((prevData) => ({
                    ...prevData,
                    company_identities: {
                      ...prevData.company_identities,
                      [platform]: e.target.value, // Update the URL for the platform
                    },
                  }))
                }
                value={url || ''}
                disabled={!editMode}
                style={{ backgroundColor: isresolved ? '#DADADA' : 'white', height: '60px' }}
                InputProps={{
                  style: { padding: '30px 0px', height: '40px' },
                }}
                InputLabelProps={{
                  style: { color: url ? '#6E6E6E' : '#B0B0B0' },
                }}
              />
            ))}
        </Grid>
        <Grid item xs={12} md={6}>
          <Grid item xs={12} md={6}>
            <FormControl fullWidth>
              <Select
                label="Priority"
                multiple
                value={formData?.priority || []}
                style={{ backgroundColor: isresolved ? "#DADADA" : "white", height: "60px" }}
                renderValue={(selected) => (
                  <div style={{ display: "flex", flexWrap: "wrap", gap: "5px" }}>
                      <Chip
                        key={formData?.priority}
                        label={formData?.priority}
                        style={{ backgroundColor: "#e0f7fa", color: "#006064" }}
                      />
                  </div>
                )}
                disabled={!editMode}
              >
              </Select>
            </FormControl>
          </Grid>

          <Typography variant="subtitle1">
            Last Updated At {formData?.updated_at || "N/A"}
          </Typography>
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            label="Message"
            variant="outlined"
            placeholder="Write a message to the developer"
            fullWidth
            name="developerMessage"
            onChange={handleInputChange}
            value={formData.developerMessage || ''}
            disabled={!editMode}
            style={{ backgroundColor: isresolved ? "#DADADA" : "white", height: "120px" }}
            InputProps={{
              style: { padding: '60px 14px', height: '100px', color: 'black' },
            }}
            InputLabelProps={{
              style: { color: '#6E6E6E' },
              shrink: true,
            }}
            multiline
          />
        </Grid>
      </Grid>
    </div>
  )
}

export default ExpandedPersonView