import React, { useState, useEffect } from 'react';
import Cookies from 'js-cookie';
import {
    Grid,
    TextField,
    InputAdornment,
    Button,
    ThemeProvider,
    createTheme,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TablePagination,
    Chip,
    CircularProgress,
    Typography,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import RefreshIcon from '@mui/icons-material/Refresh';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ExpandedClientView from './ExpandedClientView';
import { path } from '../../apiCentral/path';

const theme = createTheme({
    components: {
        MuiTextField: {
            styleOverrides: {
                root: {
                    "& .MuiInputBase-root": {
                        height: 40,
                        marginBottom: 20,
                    },
                },
            },
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    height: 40,
                    marginBottom: 20,
                },
            },
        },
    },
});

const Enrichment = () => {
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(0);
    const [clients, setClients] = useState([]);
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const [reload, setReload] = useState({});
    const [filter, setFilter] = useState("");
    const [processFilter, setProcessFilter] = useState([]);
    const [total, setTotal] = useState(0);
    const [searchString, setSearchString] = useState("");
    const [loading, setLoading] = useState(false);
    const [selectedClient, setSelectedClient] = useState(null);
    const [expandedRow, setExpandedRow] = useState(null);
    const token = Cookies.get('token');

    const handleSearchChange = (event) => setSearchString(event.target.value);

    const handleExpandClick = (index) => {
        setExpandedRow(expandedRow === index ? null : index);
    };

    useEffect(() => {
        const fetchClients = async () => {
            setLoading(true);
            try {
                let payload = {
                    page: currentPage,
                    size: rowsPerPage, // Ensure size is dynamic
                    search: searchString,
                };
                if (startDate) {
                    payload.start_date = startDate;
                    payload.end_date = endDate || new Date().toISOString().split('T')[0];
                }
                if (filter) {
                    payload.filter = Array.isArray(filter) ? filter : [filter];
                }
                if (processFilter) {
                    payload.processFilter = Array.isArray(processFilter) ? processFilter : [processFilter];
                }
    
                const response = await fetch(path.getClientsEnrichment, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": token,
                    },
                    body: JSON.stringify(payload),
                });
    
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
    
                const res = await response.json();
                // Slice rows if backend returns more than needed
                const slicedClients = (res.clients || []).slice(0, rowsPerPage);
                setClients(res.clients.slice(currentPage * rowsPerPage, (currentPage + 1) * rowsPerPage));
                setTotal(res.total || 0);
            } catch (error) {
                console.error("Error fetching data:", error);
            } finally {
                setLoading(false);
            }
        };
    
        fetchClients();
    }, [currentPage, rowsPerPage, reload, searchString, startDate, endDate, filter, processFilter]);
    

    const handleRowClick = (row) => {
        setSelectedClient(row);
    };

    const handleBackToTable = () => {
        setSelectedClient(null);
    };

    return (
        <ThemeProvider theme={theme}>
            <form onSubmit={(e) => e.preventDefault()}>
                <Grid container alignItems="center" justifyContent="space-between" spacing={2}>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            fullWidth
                            variant="outlined"
                            placeholder="Search for name, company name, designation"
                            value={searchString}
                            onChange={handleSearchChange}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <SearchIcon />
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </Grid>
                    <Grid item>
                        <Button
                            variant="contained"
                            startIcon={<RefreshIcon />}
                            sx={{ textTransform: "none", fontSize: "1rem" }}
                            onClick={() => setReload({})}
                        >
                            Refresh
                        </Button>
                    </Grid>
                </Grid>
            </form>

            {loading ? (
                <Grid container justifyContent="center" alignItems="center" style={{ height: '100vh' }}>
                    <CircularProgress />
                </Grid>
            ) : selectedClient ? (
                <ExpandedClientView selectedClient={selectedClient} onBack={handleBackToTable} />
            ) : (
                <Paper sx={{ width: "100%", overflow: "hidden" }}>
                    {clients.length === 0 ? (
                        <Typography variant="h6" align="center" sx={{ padding: 2 }}>
                            No clients found. Please adjust your filters or try again later.
                        </Typography>
                    ) : (
                        <TableContainer>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell sx={{ fontWeight: 'bold', width: '30%' }}>Client Name</TableCell>
                                        <TableCell sx={{ fontWeight: 'bold', width: '20%' }}>SR Profile Count</TableCell>
                                        <TableCell sx={{ fontWeight: 'bold', width: '30%' }}>Last Entry</TableCell>
                                        <TableCell sx={{ fontWeight: 'bold', width: '15%' }}>Account Status</TableCell>
                                        <TableCell sx={{ fontWeight: 'bold', width: '5%' }}></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {clients.map((item, index) => (
                                        <TableRow
                                            key={item._id || index}
                                            style={{
                                                backgroundColor: index % 2 === 1 ? "white" : "#ECECEC",
                                            }}
                                            onClick={() => handleRowClick(item)}
                                        >
                                            <TableCell>{item.display_name || "N/A"}</TableCell>
                                            <TableCell>{item.agent_count || "0"}</TableCell>
                                            <TableCell>{item.lastEntry || "N/A"}</TableCell>
                                            <TableCell>
                                                <Chip
                                                    label={item.is_active ? "Active" : "Inactive"}
                                                    sx={{
                                                        backgroundColor: item.is_active ? "#46B973" : "#9E9E9E",
                                                        color: "white",
                                                    }}
                                                />
                                            </TableCell>
                                            <TableCell>
                                                <ArrowForwardIcon color="primary" />
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    )}
                    <TablePagination
                        rowsPerPageOptions={[5, 10, 25]}
                        component="div"
                        count={total}
                        rowsPerPage={rowsPerPage}
                        page={currentPage}
                        onPageChange={(event, newPage) => {
                            setCurrentPage(newPage);
                        }}
                        onRowsPerPageChange={(event) => {
                            const newRowsPerPage = parseInt(event.target.value, 10);
                            setRowsPerPage(newRowsPerPage);
                            setCurrentPage(0);
                        }}
                    />
                </Paper>
            )}
        </ThemeProvider>
    );
};

export default Enrichment;
