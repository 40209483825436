import React, { useEffect, useState } from "react";
import { Button, Box, Typography } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import Analytics from '@mui/icons-material/Analytics';
import GroupsIcon from '@mui/icons-material/Groups';
import Cookies from 'js-cookie';
import ExitToApp from '@mui/icons-material/ExitToApp';
import AnalyticsContainer from './analytics/AnalyticsContainer';
import './dashboardContainer.css';
import MFI from "./MarkForImprovement/MFI";
import question from '../assests/images/question 1.png';
import Client from './Client/ClientContainer';
import Enrichment from "./Enrichment/EnrichmentContainer";
const _ = require("lodash");

function DashboardContainer() {
  const navigate = useNavigate();
  const location = useLocation();
  const [selectedComponent, setSelectedComponent] = useState(
    new URLSearchParams(location.search).get("tab") || "analytics"
  );
  const [orgDisplayName, setOrgDisplayName] = useState(null);

  useEffect(() => {
    const token = Cookies.get("token");
    if (!token) {
      navigate('/');
    }
  }, [navigate]);

  const handleComponentChange = (e, componentName) => {
    e.preventDefault();
    setSelectedComponent(componentName);
    navigate(`/dashboard?tab=${componentName}`);
  };

  const handleLogout = async (e) => {
    try {
      navigate('/');
      Cookies.remove('token');
    } catch (error) {
      alert("Internal Server Error! Please Try Again");
    }
  };

  useEffect(() => {
    const orgDisplayNameInCookie = Cookies.get('user_type');
    if (!_.isEmpty(orgDisplayNameInCookie)) {
      setOrgDisplayName(orgDisplayNameInCookie);
    }
  }, []);

  return (
    <div className="dashboard-container">
      <div className="menu-container">
        <div className="menu-buttons">
          <Button
            startIcon={<Analytics />}
            onClick={(e) => handleComponentChange(e, "analytics")}
            sx={{
              textTransform: "none",
              fontSize: "1rem",
              justifyContent: "flex-start",
              borderTopRightRadius: "20px",
              borderBottomRightRadius: "20px",
            }}
            style={{
              width: "100%",
              color: selectedComponent === "analytics" ? "black" : "grey",
              backgroundColor: selectedComponent === "analytics" ? "#F0F8FF" : "inherit",
              borderLeft: selectedComponent === "analytics" ? "5px solid #5D5FEF" : "inherit",
            }}
          >
            Analytics
          </Button>
          <Button
            startIcon={<GroupsIcon />}
            onClick={(e) => handleComponentChange(e, "client")}
            sx={{
              textTransform: "none",
              fontSize: "1rem",
              justifyContent: "flex-start",
              borderTopRightRadius: "20px",
              borderBottomRightRadius: "20px",
            }}
            style={{
              width: "100%",
              color: selectedComponent === "client" ? "black" : "grey",
              backgroundColor: selectedComponent === "client" ? "#F0F8FF" : "inherit",
              borderLeft: selectedComponent === "client" ? "5px solid #5D5FEF" : "inherit",
            }}
          >
            Client
          </Button>
          <Button
            startIcon={<img src={question} alt="Question Icon" style={{ width: '24px', height: '24px' }} />}
            onClick={(e) => handleComponentChange(e, "mfi")}
            sx={{
              textTransform: "none",
              fontSize: "1rem",
              justifyContent: "flex-start",
              borderTopRightRadius: "20px",
              borderBottomRightRadius: "20px",
            }}
            style={{
              width: "100%",
              color: selectedComponent === "mfi" ? "black" : "grey",
              backgroundColor: selectedComponent === "mfi" ? "#F0F8FF" : "inherit",
              borderLeft: selectedComponent === "mfi" ? "5px solid #5D5FEF" : "inherit",
            }}
          >
            MFI
          </Button>
          <Button
            startIcon={<Analytics />}
            onClick={(e) => handleComponentChange(e, "enrichment")}
            sx={{
              textTransform: "none",
              fontSize: "1rem",
              justifyContent: "flex-start",
              borderTopRightRadius: "20px",
              borderBottomRightRadius: "20px",
            }}
            style={{
              width: "100%",
              color: selectedComponent === "enrichment" ? "black" : "grey",
              backgroundColor: selectedComponent === "enrichment" ? "#F0F8FF" : "inherit",
              borderLeft: selectedComponent === "enrichment" ? "5px solid #5D5FEF" : "inherit",
            }}
          >
            Enrichment
          </Button>
        </div>
        <div className="logout-button">
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignSelf: 'flex-start',
              borderRadius: '8px',
              padding: '20px',
              backgroundColor: 'white',
              boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
            }}
          >
          </Box>
          {orgDisplayName && (
          <Typography
            sx={{ fontSize: "1rem", fontWeight: "bold", paddingLeft: "15px", paddingTop: "10px" }}
          >
            QA ADMIN
          </Typography>
        )}
          <Button
            className="logout-button"
            startIcon={<ExitToApp />}
            onClick={handleLogout}
            sx={{ textTransform: "none", fontSize: "1rem" }}
          >
            Log out
          </Button>
        </div>
      </div>
      <div className="component-display">
        {selectedComponent === "analytics" ? (
          <AnalyticsContainer />
        ) : selectedComponent === "client" ? (
          <Client />
        ) : selectedComponent === "mfi" ? (
          <MFI />
        ) : selectedComponent === "enrichment" ? (
          <Enrichment />
        ) :(
          <h1>Nothing Selected</h1>
        )}
      </div>
    </div>
  );
}

export default DashboardContainer;