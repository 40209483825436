import React, { useState, useEffect } from "react";
import axios from "axios";

import RotateRightIcon from '@mui/icons-material/RotateRight';
import {
  TextField,
  Grid,
  Typography,
  CardMedia,
  IconButton,
  Tooltip,
  InputAdornment,
  CardContent,
  Card,
  Divider,
  Button,
  MenuItem,
  FormControl,
  InputLabel,
  Select
} from "@mui/material";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import EditIcon from "@mui/icons-material/Edit";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import Cookies from 'js-cookie';
import ResolvedPopup from './popups/ResolvedPopup';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { path } from "../../apiCentral/path";
import { format } from 'date-fns';
import LaunchIcon from '@mui/icons-material/Launch';
import WebsitePopup from "./popups/WebsitePopup";
import AddTaskIcon from '@mui/icons-material/AddTask';
import update from '../../assests/update.svg';
import CircularProgress from '@mui/material/CircularProgress';
const _ = require("lodash");

const fetchImageAsBase64 = async (url, authToken) => {
  try {
    const response = await axios.post(`https://cdn.hayashirsha.com/${url}`, null, {
      headers: {
        'Authorization': authToken,
      },
      responseType: 'arraybuffer',
    });

    const base64 = btoa(
      new Uint8Array(response.data)
        .reduce((data, byte) => data + String.fromCharCode(byte), '')
    );
    return `data:image/jpeg;base64,${base64}`;
  } catch (error) {
    return null;
  }
};

const MfiDetails = ({ data }) => {
  const [formData, setFormData] = useState(data);
  const token = Cookies.get('token');
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [frontImgBase64, setFrontImgBase64] = useState(null);
  const [backImgBase64, setBackImgBase64] = useState(null);
  const [editMode, setEditMode] = useState(false);
  const [popupOpen, setPopupOpen] = useState(false);
  const [editedFields, setEditedFields] = useState({});
  const [lastUpdatedUser, setLastUpdatedUser] = useState(formData?.qa_user_display_name);
  const [lastUpdatedTime, setLastUpdatedTime] = useState(formData?.qa_updated_at);
  const [isresolved, setIsResolved] = useState(formData?.resolved);
  const [frontImgRotation, setFrontImgRotation] = useState(0);
  const [backImgRotation, setBackImgRotation] = useState(0);
  const [websitePopupOpen, setWebsitePopupOpen] = useState(false);
  const [urlToRedirect, setUrlToRedirect] = useState('');
  const editedFieldsByQA = formData?.edited_fields_by_qa || [];
  const [loading, setLoading] = useState({ front: true, back: true });
  const [imageUpdateForRotate, setImageUpdateForRotate] = useState(0)
  const availableCategories = [
    "Others",
  ];
  // console.log(formData);

  const renderUpdateIcon = (fieldName) => (
    editedFieldsByQA.includes(fieldName) ? (
      <InputAdornment position="end">
        <AddTaskIcon style={{ color: 'green', paddingRight: "10px", height: "35px", width: "35px" }} />
      </InputAdornment>
    ) : null
  );

  // Compares two arrays for equality by checking their lengths and each item.
  const areArraysEqual = (arr1, arr2) => {
    if (arr1.length !== arr2.length) return false;
    return arr1.every((item, index) => item === arr2[index]);
  };

  // Compares the value of a field in `data` with its counterpart in `data.front_img_entities` to check for differences.
  const compareFields = (fieldName) => {
    if (Array.isArray(data?.[fieldName]) && Array.isArray(data?.front_img_entities?.[fieldName])) {
      return !areArraysEqual(data[fieldName], data.front_img_entities[fieldName]);
    }
    return data[fieldName] !== data.front_img_entities[fieldName];
  };

  const fetchImages = async () => {
    if (formData.front_img_bucket && typeof(formData.front_img_bucket) == 'string') {
      setLoading((prev) => ({ ...prev, front: true }));
      const frontImg = await fetchImageAsBase64(formData.front_img_bucket, token);
      setFrontImgBase64(frontImg);
      setLoading((prev) => ({ ...prev, front: false }));
    }
    if (formData.back_img_bucket && typeof(formData.back_img_bucket) == 'string') {
      setLoading((prev) => ({ ...prev, back: true }));
      const backImg = await fetchImageAsBase64(formData.back_img_bucket, token);
      setBackImgBase64(backImg);
      setLoading((prev) => ({ ...prev, back: false }));
    }
  };
  useEffect(() => {


    fetchImages();
  }, [formData.front_img_bucket, formData.back_img_bucket, token]);

  // Rotates the front image by 90 degrees in the specified direction ('left' or 'right').
  const rotateFrontImage = (direction) => {
    setFrontImgRotation(prevRotation => {
      const newRotation = (prevRotation + (direction === 'left' ? -90 : 90)) % 360;
      return newRotation < 0 ? newRotation + 360 : newRotation;
    });
  };

  // Rotates the back image by 90 degrees in the specified direction ('left' or 'right').
  const rotateBackImage = (direction) => {
    setBackImgRotation(prevRotation => {
      const newRotation = (prevRotation + (direction === 'left' ? -90 : 90)) % 360;
      return newRotation < 0 ? newRotation + 360 : newRotation;
    });
  };

  // Updates the state with the new value of an input field when its value changes.
  // Sets the edited fields and form data accordingly.
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setEditedFields((prev) => ({
      ...prev,
      [name]: value,
    }));
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  // Updates the form data with the new category value when the category input changes.
  // Marks the categories field as edited.
  const handleCategoryChange = (e) => {
    const { value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      categories: value,
    }));

    setEditedFields((prevState) => ({
      ...prevState,
      categories: true,
    }));
  };

  const formatURL = (url) => {
    if (!url) return '';
    return url?.startsWith('https://') ? url : `https://${url}`;
  };

  //Website confirm pop up
  const handleWebsitePopupOpen = (url) => {
    setUrlToRedirect(url);
    setWebsitePopupOpen(true);
  };

  const handleWebsitePopupClose = () => {
    setWebsitePopupOpen(false);
    setUrlToRedirect('');
  };

  const handleWebsiteConfirm = () => {
    if (!_.isEmpty(urlToRedirect)) {
      window.open(urlToRedirect);
    }
    handleWebsitePopupClose();
  };

  // Saves updated data, including changes and image rotations, to the server and updates state based on the server response.
  const handleSave = async () => {
    // console.log("formData:", formData); 
    // Create a temporary version of updated data
    const updatedData = {
      scan_uid: formData.scan_uid,
      uid: formData._id,
      ...Object.keys(formData).reduce((changes, key) => {
        if (formData[key] !== data[key]) {
          if (key === 'emails' && typeof formData[key] === 'string') {
            changes[key] = formData[key].split(',').map(email => email.trim());
          } else if (key === 'mobile_numbers' && typeof formData[key] === 'string') {
            changes[key] = formData[key].split(',').map(number => number.trim());
          } else if (key === 'categories' && Array.isArray(formData[key])) {
            changes[key] = formData[key];
          } else {
            changes[key] = formData[key];
          }
        }
        return changes;
      }, {}),
    };

    // Handle image data updates if rotation is not 0
    if (frontImgRotation !== 0) {
      updatedData.front_img_bucket = formData.front_img_bucket;
      updatedData.frontImgRotation = frontImgRotation;
    }

    if (backImgRotation !== 0) {
      updatedData.back_img_bucket = formData.back_img_bucket;
      updatedData.backImgRotation = backImgRotation;
    }

    delete updatedData._id; // Ensure _id is removed

    // Optimistically update formData immediately
    setFormData(prevData => ({
      ...prevData,
      ...updatedData,
    }));

    // Reset rotations and edit mode immediately after updating formData
    setFrontImgRotation(0);
    setBackImgRotation(0);
    setEditMode(false);
    setEditedFields({});

    // Set loading state
    setIsLoading(true);

    try {
      const response = await axios.put(path.edit, updatedData, {
        headers: {
          'Authorization': token,
          'Content-Type': 'application/json',
        },
      });
      

      if (response.status === 200) {
        // Update only necessary fields from the response
        const { front_img_bucket, back_img_bucket } = response.data.data || {};
        

        setFormData(prevData => ({
          ...prevData,
          front_img_bucket: fetchImages(front_img_bucket) || prevData.front_img_bucket,
          back_img_bucket: fetchImages(back_img_bucket) || prevData.back_img_bucket,
          // Any other fields to be updated...
        }));

        // Fetch images immediately after updating formData if buckets changed
        if (front_img_bucket && front_img_bucket !== formData.front_img_bucket) {
          setLoading(prev => ({ ...prev, front: true }));
          const frontImg = await fetchImageAsBase64(front_img_bucket, token);
          setFrontImgBase64(frontImg);
          setLoading(prev => ({ ...prev, front: false }));
        }

        if (back_img_bucket && back_img_bucket !== formData.back_img_bucket) {
          setLoading(prev => ({ ...prev, back: true }));
          const backImg = await fetchImageAsBase64(back_img_bucket, token);
          setBackImgBase64(backImg);
          setLoading(prev => ({ ...prev, back: false }));
        }

      } else {
        setError('Failed to save changes.');
      }
    } catch (error) {
      console.error('Error details:', error);
      // Optionally revert formData state here if needed
      setError('An error occurred while saving changes.');
    } finally {
      setIsLoading(false);
    }
  };

  const handleCancel = () => {
    setFormData(data);
    setEditMode(false);
    setEditedFields({});
  };

  //Funtion to handle copy 
  const handleCopyClick = (e, contentType, contentToCopy) => {
    e.preventDefault();
    if (navigator.clipboard) {
      navigator.clipboard.writeText(contentToCopy).catch((error) => { });
    } else {
      const textArea = document.createElement("textarea");
      textArea.value = contentToCopy;
      document.body.appendChild(textArea);
      textArea.select();
      document.execCommand('copy');
      document.body.removeChild(textArea);
    }
  };

  const toggleEditMode = () => {
    setEditMode(true);
  };

  const handlePopupOpen = () => {
    setPopupOpen(true);
  };

  const handlePopupClose = () => {
    setPopupOpen(false);
  };

  // Function to handle the "Continue" action in the popup, makes an API call to update resolved status
  const handlePopupContinue = async () => {
    try {
      // console.log('Before API call:', formData);
      const response = await fetch(path.resolved, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          "Authorization": token,
        },
        body: JSON.stringify({
          scan_uid: formData.scan_uid,
        }),
      });
      const res = await response.json();
      // console.log('API Response:', res);
      if (response.ok) {
        // console.log('New formData resolved value:', res.data?.status || res.status);
        setFormData((prevData) => {
          // console.log('Previous formData:', prevData);
          prevData.resolved = true;
          // console.log('Updated formData:', prevData);
          // console.log(formData);
          return prevData;
        });
        handlePopupClose();
      } else {
        setError('Failed to update resolved status.');
      }
    } catch (error) {
      setError('An error occurred during the request.');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div style={{ backgroundColor: "#ECECEC" }}>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-end",
          marginBottom: 10,
          backgroundColor: "#ECECEC"
        }}
      >
        <Button
          variant="contained"
          sx={{
            textTransform: "none",
            fontSize: "1rem",
            marginRight: "25px",
            marginTop: "20px",
            backgroundColor: isresolved ? "#DADADA" : "primary",
          }}
          onClick={handlePopupOpen}
          disabled={isresolved}
        >
          Resolved
        </Button>
        {editMode ? (
          <div>
            {isLoading ? (
              <CircularProgress size={24} style={{ color: "black" }} /> // Display the loading spinner
            ) : (
              <>
                <IconButton onClick={handleSave} style={{ color: "black" }}>
                  <CheckIcon />
                </IconButton>
                <IconButton onClick={handleCancel} style={{ color: "black" }}>
                  <CloseIcon />
                </IconButton>
              </>
            )}
          </div>
        ) : (
          <IconButton onClick={toggleEditMode} style={{ color: "black" }}>
            <EditIcon />
          </IconButton>
        )}
      </div>
      <ResolvedPopup
        open={popupOpen}
        handleClose={handlePopupClose}
        handleContinue={handlePopupContinue}
      />
      <Grid container spacing={2} >
        <Grid item xs={12} md={6}>
          <TextField
            label="Feedback Message"
            variant="outlined"
            fullWidth
            value={formData.feedback_message || 'Not Present'}
            disabled
            style={{ backgroundColor: "#DADADA", height: "120px" }}
            InputProps={{
              style: { padding: '60px 14px', height: '100px' },
            }}
            InputLabelProps={{
              style: { color: '#6E6E6E' },
              shrink: true,
            }}
            multiline
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            label="QA Feedback"
            variant="outlined"
            placeholder="Write a message to the developer"
            fullWidth
            name="developerMessage"
            onChange={handleInputChange}
            value={formData.developerMessage || ''}
            disabled={!editMode}
            style={{ backgroundColor: isresolved ? "#DADADA" : "white", height: "120px" }}
            InputProps={{
              style: { padding: '60px 14px', height: '100px', color: 'black' },
            }}
            InputLabelProps={{
              style: { color: '#6E6E6E' },
              shrink: true,
            }}
            multiline
          />
        </Grid>

      </Grid>
      <Divider style={{ margin: "20px 0" }} />
      <Grid container spacing={2} justifyContent="space-between" alignItems="center">
        <Grid item xs={6} sm={6} md={6}>
          <Typography variant="subtitle2" fontWeight="bold">
            Last Updated By
          </Typography>
          <Typography variant="subtitle2">{lastUpdatedUser || "N/A"}</Typography>
        </Grid>
        <Grid item xs={6} sm={6} md={6} textAlign="right">
          <Typography variant="subtitle2" fontWeight="bold">
            Last Updated At
          </Typography>
          <Typography variant="subtitle2">{lastUpdatedTime || "N/A"}</Typography>
        </Grid>
      </Grid>
      <Divider style={{ margin: "20px 0" }} />
      
      <Grid container spacing={2} marginBottom={5} style={{ backgroundColor: "#ECECEC" }}>
      <Tooltip title={`Recent Data: ${formData?.scan_details?.person_name || 'N/A'}`} arrow>
        <Grid item xs={12} md={6}>
          <TextField
            label="Name"
            variant="outlined"
            fullWidth
            name="person_name"
            onChange={editMode ? handleInputChange : undefined}
            value={formData?.person_name || ''}
            disabled={!editMode}
            style={{ backgroundColor: isresolved ? "#DADADA" : "white", height: "60px" }}
            InputProps={{
              style: { padding: '30px 0px', height: '40px', color: 'black' },
              endAdornment: (
                <>
                  {compareFields('person_name') && (
                    <InputAdornment position="end" style={{ paddingRight: '10px' }}>
                      <img src={update} alt="" />
                    </InputAdornment>
                  )}
                  {renderUpdateIcon('person_name')}
                </>
              ),
            }}
            InputLabelProps={{
              style: { color: formData?.person_name ? '#6E6E6E' : '#B0B0B0' },
            }}
          />
        </Grid>
        </Tooltip>

        <Tooltip title={`Recent Data: ${formData?.scan_details?.company_name || 'N/A'}`} arrow>
        <Grid item xs={12} md={6}>
          <TextField
            label="Company Name"
            variant="outlined"
            fullWidth
            name="company_name"
            style={{ backgroundColor: isresolved ? "#DADADA" : "white", height: "60px" }}
            onChange={editMode ? handleInputChange : undefined}
            value={formData?.company_name || ''}
            disabled={!editMode}
            InputProps={{
              style: { padding: '30px 0px', height: '40px' },
              endAdornment: (
                <>
                  {compareFields('company_name') && (
                      <InputAdornment position="end" style={{ paddingRight: '10px' }}>
                        <img src={update} alt="" />
                      </InputAdornment>
                  )}
                  {renderUpdateIcon('company_name')}

                </>
              ),
            }}
            InputLabelProps={{
              style: { color: formData?.company_name ? '#6E6E6E' : '#B0B0B0' },
            }}
          />
        </Grid>
        </Tooltip>

        <Tooltip title={`Recent Data: ${formData?.scan_details?.designation || 'N/A'}`} arrow>
        <Grid item xs={12} md={6}>
          <TextField
            label="Designation"
            variant="outlined"
            fullWidth
            name="designation"
            style={{ backgroundColor: isresolved ? "#DADADA" : "white", height: "60px" }}
            onChange={editMode ? handleInputChange : undefined}
            value={formData?.designation || ''}
            disabled={!editMode}
            InputProps={{
              style: { padding: '30px 0px', height: '40px' },
              endAdornment: (
                <>
                  {compareFields('designation') && (
                    <InputAdornment position="end" style={{ paddingRight: '10px' }}>
                      <img src={update} alt="" />
                    </InputAdornment>
                  )}
                  {renderUpdateIcon('designation')}
                </>
              ),
            }}
            InputLabelProps={{
              style: { color: formData?.designation ? '#6E6E6E' : '#B0B0B0' },
            }}
          />
        </Grid>
        </Tooltip>

        <Tooltip title={`Recent Data: ${formData?.scan_details?.emails.join(', ') || 'N/A'}`} arrow>
        <Grid item xs={12} md={6}>
          <TextField
            label="Email"
            variant="outlined"
            fullWidth
            name="emails"
            onChange={editMode ? handleInputChange : undefined}
            value={formData?.emails || ''}
            disabled={!editMode}
            style={{ backgroundColor: isresolved ? "#DADADA" : "white", height: "60px" }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end" style={{ paddingRight: '10px' }}>
                  {formData?.emails && compareFields('emails') && (
                    <img src={update} alt="" />
                  )}
                  {renderUpdateIcon('emails')}
                  <Tooltip title="Copy">
                    <IconButton onClick={(e) => handleCopyClick(e, "emails", formData?.emails.join(', ') || '')}>
                      <ContentCopyIcon />
                    </IconButton>
                  </Tooltip>
                </InputAdornment>
              ),
              style: { padding: '30px 0px', height: '40px' },
            }}
            InputLabelProps={{
              style: { color: formData?.emails?.length ? '#6E6E6E' : '#B0B0B0' },
            }}
          />
        </Grid>
        </Tooltip>

        <Tooltip title={`Recent Data: ${formData?.scan_details?.mobile_numbers.join(', ') || 'N/A'}`} arrow>
        <Grid item xs={12} md={6}>
          <TextField
            label="Phone Number"
            variant="outlined"
            fullWidth
            name="mobile_numbers"
            onChange={editMode ? handleInputChange : undefined}
            value={formData?.mobile_numbers || ''}
            disabled={!editMode}
            style={{ backgroundColor: isresolved ? "#DADADA" : "white", height: "60px" }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end" style={{ paddingRight: '10px' }}>
                  {formData?.mobile_numbers && compareFields('mobile_numbers') && (
                    <img src={update} alt="" />
                  )}
                  {renderUpdateIcon('mobile_numbers')}
                  <Tooltip title="Copy">
                    <IconButton onClick={(e) => handleCopyClick(e, "mobile_numbers", formData?.mobile_numbers || '')}>
                      <ContentCopyIcon />
                    </IconButton>
                  </Tooltip>
                </InputAdornment>
              ),
              style: { padding: '30px 0px', height: '40px' },
            }}
            InputLabelProps={{
              style: { color: formData?.mobile_numbers ? '#6E6E6E' : '#B0B0B0' },
            }}
          />
        </Grid>
        </Tooltip>

        <Tooltip title={`Recent Data: ${formData?.scan_details?.website || 'N/A'}`} arrow>
        <Grid item xs={12} md={6}>
          <TextField
            label="Website/url"
            variant="outlined"
            fullWidth
            name="website"
            onChange={editMode ? handleInputChange : undefined}
            value={formData?.website || ''}
            disabled={!editMode}
            style={{ backgroundColor: isresolved ? "#DADADA" : "white", height: "60px" }}
            InputProps={{
              endAdornment: !editMode && formData?.website && (
                <InputAdornment position="end" style={{ paddingRight: '10px' }}>
                  {formData?.website && compareFields('website') && (
                    <img src={update} alt="" />
                  )}
                  {renderUpdateIcon('website')}
                  <Tooltip title="Visit Website">
                    <IconButton onClick={() => handleWebsitePopupOpen(formatURL(formData?.website))}>
                      <LaunchIcon />
                    </IconButton>
                  </Tooltip>
                </InputAdornment>
              ),
              style: { padding: '30px 0px', height: '40px' },
            }}
            InputLabelProps={{
              style: { color: formData?.website ? '#6E6E6E' : '#B0B0B0' },
            }}
          />
        </Grid>
        </Tooltip>

        <WebsitePopup
          isPopupOpen={websitePopupOpen}
          handlePopupClose={handleWebsitePopupClose}
          handleConfirm={handleWebsiteConfirm}
        />

        <Tooltip title={`Recent Data: ${formData?.scan_details?.address || 'N/A'}`} arrow>
        <Grid item xs={12} md={6}>
          <TextField
            label="Address"
            variant="outlined"
            fullWidth
            name="address"
            style={{ backgroundColor: isresolved ? "#DADADA" : "white", height: "60px" }}
            onChange={editMode ? handleInputChange : undefined}
            value={formData?.address || ''}
            disabled={!editMode}
            InputProps={{
              style: { padding: '30px 0px', height: '40px' },
              endAdornment: (
                <>
                  {compareFields('address') && (
                    <InputAdornment position="end" style={{ paddingRight: '10px' }}>
                      <img src={update} alt="" />
                    </InputAdornment>
                  )}
                  {renderUpdateIcon('address')}
                </>
              ),
            }}
            InputLabelProps={{
              style: { color: formData?.address ? '#6E6E6E' : '#B0B0B0' },
            }}
          />
        </Grid>
        </Tooltip>

        <Tooltip title={`Recent Data: ${formData?.scan_details?.categories || 'N/A'}`} arrow>
        <Grid item xs={12} md={6}>
          <FormControl fullWidth>
            <InputLabel sx={{ color: '#6E6E6E' }}>Categories</InputLabel>
            <Select
              label="Categories"
              multiple
              value={formData.categories || []}
              style={{ backgroundColor: isresolved ? "#DADADA" : "white", height: "60px" }}
              onChange={handleCategoryChange}
              renderValue={(selected) => (
                <div>
                  {selected.join(', ')}
                </div>
              )}
              disabled={!editMode}
              endAdornment={(
                <InputAdornment position="end">
                  <IconButton>
                    {renderUpdateIcon('categories')}
                  </IconButton>
                </InputAdornment>
              )}
            >
              {availableCategories.map((category) => (
                <MenuItem key={category} value={category}>
                  {category}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        </Tooltip>
        <Grid item xs={12} md={6}>
          <TextField
            label="Marked From"
            variant="outlined"
            fullWidth
            name="marked_from"
            style={{ backgroundColor: isresolved ? "#DADADA" : "white", height: "60px" }}
            value={formData?.device}
            disabled
            InputProps={{
              style: { padding: '30px 0px', height: '40px' },
            }}
            InputLabelProps={{
              style: { color: formData?.marked_from ? '#6E6E6E' : '#B0B0B0' },
            }}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            label="Created at"
            variant="outlined"
            fullWidth
            name="created_at"
            style={{ backgroundColor: isresolved ? "#DADADA" : "white", height: "60px" }}
            value={formData?.created_at ? format(new Date(formData.created_at), 'MMMM dd, yyyy h:mm a') : ''}
            disabled
            InputProps={{
              style: { padding: '30px 0px', height: '40px' },
            }}
            InputLabelProps={{
              style: { color: formData?.created_at ? '#6E6E6E' : '#B0B0B0' },
            }}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Card style={{ backgroundColor: isresolved ? "#DADADA" : "white" }}>
            <CardContent>
              <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Typography variant="body2" color="textSecondary" gutterBottom>
                  Front Image
                </Typography>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  {renderUpdateIcon('front_img_bucket')}
                  {editMode && (
                    <Button onClick={() => rotateFrontImage('right')}>
                      <RotateRightIcon />
                    </Button>
                  )}
                </div>
              </div>
            </CardContent>
            {loading.front ? (
              <div style={{ textAlign: 'center', padding: '16px' }}>
                <CircularProgress />
              </div>
            ) : (
              <CardMedia
                component="img"
                alt="Front Image"
                style={{
                  maxHeight: "250px",
                  width: "100%",
                  height: "auto",
                  objectFit: "contain",
                  transform: `rotate(${frontImgRotation}deg)`,
                }}
                image={frontImgBase64}
                title="Front Image"
              />
            )}
          </Card>
        </Grid>
        {backImgBase64 ? (
          <Grid item xs={12} md={6}>
            <Card style={{ backgroundColor: isresolved ? "#DADADA" : "white" }}>
              <CardContent>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                  <Typography variant="body2" color="textSecondary" gutterBottom>
                    Back Image
                  </Typography>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    {renderUpdateIcon('back_img_bucket')}
                    {editMode && (
                      <Button onClick={() => rotateBackImage('right')}>
                        <RotateRightIcon />
                      </Button>
                    )}
                  </div>
                </div>
              </CardContent>
              {loading.back ? (
                <div style={{ textAlign: 'center', padding: '16px' }}>
                  <CircularProgress />
                </div>
              ) : (
                <CardMedia
                  component="img"
                  alt="Back Image"
                  style={{
                    maxHeight: "250px",
                    width: "100%",
                    height: "auto",
                    objectFit: "contain",
                    transform: `rotate(${backImgRotation}deg)`,
                  }}
                  image={backImgBase64}
                  title="Back Image"
                />
              )}
            </Card>
          </Grid>
        ) : null}
      </Grid>
    </div>
  );
};

export default MfiDetails;
